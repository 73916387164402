import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import COLORS from '../../assets/colors';
import { makeStyles } from '@mui/styles';
import { css } from 'styled-components';

import fire from '../../fire';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import BTC_logo from '../../assets/images/btc.png';
import ETH_logo from '../../assets/images/eth.png';
import TEZ_logo from '../../assets/images/tez.png';
import Pay_methods from '../../assets/images/payments_methods.png';

import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';


import { getAuth, onAuthStateChanged } from "firebase/auth";

import { getFirestore, collection, addDoc, getDocs, deleteDoc, doc } from "firebase/firestore"; 
const db = getFirestore();
const auth = getAuth(fire);


const methods = {
  cc: "CARTE BANCAIRE",
  btc: "BITCOIN",
  eth: "ETHEREUM",
  tez: "TEZOS",
};

const logos = {
  btc: BTC_logo,
  eth: ETH_logo,
  tez: TEZ_logo,
};

const ERROR_MESSAGES = {
  emptyCardNumber: 'Numéro de carte invalide',
  invalidCardNumber: 'Numéro de carte invalide',
  emptyExpiryDate: 'Date incorrecte !',
  monthOutOfRange: 'Mois incorrect !',
  yearOutOfRange: 'Année incorrecte !',
  dateOutOfRange: 'Date incorrecte',
  invalidExpiryDate: 'Date invalide',
  emptyCVC: 'CVC invalide',
  invalidCVC: 'CVC invalide',
}

class PaymentsMethods extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedMethod: "cc",
      openModal: false,
      deleteModal: false,
      key: "",

      methods: []
    };
  }

  async componentDidMount() {
    let r = [];
    const querySnapshot = await getDocs(collection(db, "users/"+this.props.user.id+"/pay_methods"));
    querySnapshot.forEach((doc) => {
      r.push(Object.assign(doc.data(), {id: doc.id}));
    });
    this.setState({methods: r});

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.location="/";
      } else {
        //
      }
    });
  }

  componentWillUnmount() {
  }

  addMethod() {
    this.setState({isAdding: true}, async () => {

      try {
        await addDoc(collection(db, "users/"+this.props.user.id+"/pay_methods"), {
          label: this.state.selectedMethod,
          key: this.state.key
        });
        this.setState({isAdding: false}, () => {
          window.location = "/payments_methods";
        })
      } catch (e) {
        alert("Un problème s'est passé...")
      }

    })
  }

  delete(methodId) {
    this.setState({deleteModal: true, selectedMethodId: methodId});
  }

  confirmDelete() {
    this.setState({isDeleting: true}, async () => {

      try {
        await deleteDoc(doc(db, "users/"+this.props.user.id+"/pay_methods", this.state.selectedMethodId));
        this.setState({isDeleting: false}, () => {
          window.location = "/payments_methods";
        })
      } catch (e) {
        alert("Un problème s'est passé...")
      }

    })
  }


  render() {

    const classes = this.props.classes;

    return (
      <Grid container>

        <Grid divider style={styles.cardContainer}  sm={4} md={3} xs={12}>

          <Card style={styles.cardStyle}>

            <CardContent style={styles.addCardContent}>
              <img src={Pay_methods} alt="methods" style={styles.addIcon} />
            </CardContent>

            <CardActions style={{ display:'flex', justifyContent:'center' }}>
              <Button onClick={() => this.setState({openModal: true})} size="small">AJOUTER</Button>
            </CardActions>
          </Card>

        </Grid>

        {
          this.state.methods.map((method) => {

          return (<Grid divider style={styles.cardContainer}  sm={4} md={3} xs={12}>
            <Card style={{backgroundColor: COLORS.appBarGrey}}>
              <CardContent>
                <div style={styles.cardHeader}>
                  {method.label === "cc" ? <CreditCardIcon style={styles.logo} /> : <img style={styles.logo} src={logos[method.label]} alt="method_icon" />}
                  <Typography style={styles.textMethod} gutterBottom>
                  { methods[method.label]}
                  </Typography>
                </div>
                <Typography style={styles.textKey} variant="body2">
                  { method.key.substring(0,5) + "*".repeat(method.key.length - 5) }
                </Typography>
                <Typography style={styles.textSold} variant="body2">
                  { method.label === "cc" ? "/" : Math.random() * 100 + " " + method.label.toUpperCase() }
                </Typography>
              </CardContent>
              <CardActions>
                <Button onClick={() => this.delete(method.id)} fullWidth style={{color: 'tomato'}} size="small">SUPPRIMER</Button>
              </CardActions>
            </Card>
          </Grid>)

          })
        }

        <Modal
          open={this.state.openModal}
          onClose={() => this.setState({openModal: false, selectedMethod: "cc"})}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Box sx={styles.modalStyle}>

            <Typography variant="h5" style={styles.modalTitle}>Ajouter une méthode de paiement</Typography>

            <FormControl style={{marginBottom: 20}} fullWidth>
              <InputLabel style={{color: 'white', backgroundColor: COLORS.appBarGrey}} id="method_selector">Moyen</InputLabel>
              <Select
                value={this.state.selectedMethod}
                labelId="method_selector"
                label="Moyen"
                onChange={(event) => this.setState({selectedMethod: event.target.value})}
                fullWidth
                style={styles.select}
                inputProps={{ classes: { icon: classes.icon }, }}
                className={classes.root}
              >
                <MenuItem value={"cc"}><ListItemIcon><CreditCardIcon style={styles.methodIcon} /></ListItemIcon><ListItemText primary="Carte bancaire" /></MenuItem>
                <MenuItem value={"btc"}><ListItemIcon><img style={styles.methodIcon} src={BTC_logo} alt="BTC_logo" /></ListItemIcon><ListItemText primary="BITECOIN" /></MenuItem>
                <MenuItem value={"eth"}><ListItemIcon><img style={styles.methodIcon} src={ETH_logo} alt="ETH_logo" /></ListItemIcon><ListItemText primary="ETHEREUM" /></MenuItem>
                <MenuItem value={"tez"}><ListItemIcon><img style={styles.methodIcon} src={TEZ_logo} alt="TEZ_logo" /></ListItemIcon><ListItemText primary="TEZOS" /></MenuItem>
              </Select>
            </FormControl>

            {
              this.state.selectedMethod === "cc" ?
              <PaymentInputsWrapper {...this.props.wrapperProps}
                  styles={{
                    fieldWrapper: {
                      base: css`
                        margin-bottom: 1rem;
                        width: 100%;
                      `
                    },
                    inputWrapper: {
                      base: css`
                        border-color: white;
                        background: #181A20;
                        width: 95%;
                      `,
                      errored: css`
                        border-color: tomato;
                      `,
                      focused: css`
                        border-color: unset;
                        box-shadow: unset;
                        outline-offset: 2px;
                      `
                    },
                    input: {
                      base: css`
                        color: white;
                        background: #181A20;
                        width: 95%;
                      `,
                      errored: css`
                        color: tomato;
                      `
                    },
                    errorText: {
                      base: css`
                        color: tomato;
                      `
                    }
                  }}
              >
                <svg {...this.props.getCardImageProps({ images })} />
                <input {...this.props.getCardNumberProps({ onChange: (event) => this.setState({key: event.target.value}) }) } />
                <input {...this.props.getExpiryDateProps()} />
                <input {...this.props.getCVCProps()} />
              </PaymentInputsWrapper>
              :
              null
            }

            {
              this.state.selectedMethod !== "cc" ?
                <TextField
                  InputLabelProps={{style : {color : 'white'} }}
                  label="Phrase mnémonique"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon style={{color: 'white'}} />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  style={styles.keyInput}
                  sx={{ input: { color: 'white' } }}
                  className={classes.root2}
                  onChange={(event) => this.setState({key: event.target.value})}
                />
              :
                null
            }

            {
              true ?
                this.state.isAdding ? <Box style={{marginTop: 20}} sx={{ width: '100%' }}> <LinearProgress /> </Box> : <Button onClick={() => this.addMethod()} disabled={this.state.key.length < 10} style={{marginTop: 20}} fullWidth variant="outlined">AJOUTER</Button>
              :
                null
            }

          </Box>
        </Modal>

        <Modal
          open={this.state.deleteModal}
          onClose={() => this.setState({deleteModal: false})}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{ timeout: 500 }}
        >
          <Box sx={styles.modalStyle}>

            <Typography variant="h5" style={styles.modalTitle}>Supprimer une méthode de paiement</Typography>

            <Typography variant="description" style={styles.modalDescription}>Êtes-vous sûr de vouloir supprimer la méthode ?</Typography>

            {this.state.isDeleting ? <Box style={{marginTop: 20}} sx={{ width: '100%' }}> <LinearProgress /> </Box> : <Button onClick={() => this.confirmDelete()} fullWidth style={{color: 'tomato', marginTop: 30}} size="small">CONFIRMER</Button>}
            {this.state.isDeleting ? null : <Button onClick={() => this.setState({deleteModal: false})} fullWidth style={{marginTop: 30}} size="small">ANNULER</Button>}

          </Box>
        </Modal>

      </Grid>
    )
  }
}

const styles = {
  cardStyle: {
    backgroundColor: COLORS.appBarGrey
  },
  cardContainer: {
    padding: 20
  },
  addCardContent: {
    display:'flex',
    justifyContent:'center'
  },
  textMethod: {
    color: 'white',
    marginLeft: 20,
    fontWeight: 'bold',
  },
  textKey: {
    color: 'white',
    marginTop: 20,
  },
  textSold: {
    color: 'white',
    marginTop: 5,
    fontWeight: 'bold',
  },
  logo: {
    width: 48,
    height: 48,
    color: 'white'
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  addIcon: {
    width: 98,
    height: 98,
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: COLORS.appBarGrey,
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  methodIcon: {
    width: 32,
    height: 32,
    color: 'white',
  },
  select: {
    color: 'white',
  },
  modalTitle: {
    color: 'white',
    paddingBottom: 30,
    fontWeight: 'bold',
    textAlign: 'center'
  },
  modalDescription: {
    color: 'white',
    paddingBottom: 30,
  },
  keyInput: {
    color: 'white'
  }
};


const useStyles = makeStyles({
  icon: {
      fill: 'white',
  },
  root: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid white"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white"
    }
  },
  root2: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid white"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white"
    }
  }
})

export default function Payments_methods_({user}) {
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES
  });
  const classes = useStyles();
  return <PaymentsMethods
            wrapperProps={wrapperProps}
            getCardImageProps={getCardImageProps}
            getCardNumberProps={getCardNumberProps}
            getExpiryDateProps={getExpiryDateProps}
            getCVCProps={getCVCProps}
            classes={classes}
            user={user}
        />
}


