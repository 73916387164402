import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import COLORS from '../assets/colors';

import { useParams } from "react-router-dom";

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PodcastsIcon from '@mui/icons-material/Podcasts';



import { getFirestore, doc, collection, getDoc, getDocs, query, where, setDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const db = getFirestore();
const storage = getStorage();


class Search extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",

      isLoading: true,

      projects: [],

      persons: [],

      selected: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false
      },


      photos: {}
    };
  }

  async componentDidMount() {

    const subsRef = collection(db, "users/"+this.props.user.id+"/subs");
    let subs = [];
    const querySnapshot3 = await getDocs(subsRef);
    querySnapshot3.forEach((doc) => {
      subs.push(doc.id);
    });


    const projectsRef = collection(db, "projects");
    const q = query(projectsRef, where("s", "array-contains-any", this.props.keywords.toLowerCase().split(" ")));
    let r = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      r.push(Object.assign(doc.data(), {id: doc.id}));
    });
    this.setState({projects: r});

    r.forEach(async (pr) => {

      getDownloadURL(ref(storage, 'projects/logos/' + pr.id))
      .then((url2) => {
        this.setState({photos: Object.assign( this.state.photos, {[pr.id]: url2} ) })
      })
      .catch((error) => {
      });
      
    });

    const personsRef = collection(db, "users");
    const q2 = query(personsRef, where("name", "array-contains-any", this.props.keywords.toLowerCase().split(" ")));
    let p = [];
    const querySnapshot2 = await getDocs(q2);
    querySnapshot2.forEach((doc) => {
      p.push(Object.assign(doc.data(), {tick: subs.includes(doc.id), id: doc.id}));
    });
    this.setState({persons: p, isLoading: false})

    p.forEach(async (user2) => {

      getDownloadURL(ref(storage, 'users/' + user2.id))
      .then((url) => {
        this.setState({photos: Object.assign( this.state.photos, {[user2.id]: url} ) })
      })
      .catch((error) => {
      });
      
    });

  }

  componentWillUnmount() {
  }

  selectChip(index) {
    let o = this.state.selected;
    o[index] = !o[index];
    this.setState({selected: o})
  }

  async toggleSub(personId) {

        const docRef = doc(db, "users/"+this.props.user.id+"/subs", personId);
        const docSnap = await getDoc(docRef);

        if(docSnap.exists()) {

          await deleteDoc(docRef);
          window.location.reload();

        } else {
          
          try {
            await setDoc(docRef, {});
            window.location.reload();
          } catch (e) {
          }

        }

  }

  render() {
    if(this.state.isLoading) {
      return (<Box sx={{ width: '100%' }}> <LinearProgress /> </Box>)
    }
    return (
      <Grid container>

        <Grid style={styles.buttonContainer} sm={12} md={12} xs={12}>
          
          <Chip onClick={() => this.selectChip(0)} style={{color: this.state.selected[0] ? 'black' : 'white', backgroundColor: this.state.selected[0] ? 'white' : 'rgb(20, 20, 20)'}} label="Environnement" />
          <Chip onClick={() => this.selectChip(1)} style={{color: this.state.selected[1] ? 'black' : 'white', backgroundColor: this.state.selected[1] ? 'white' : 'rgb(20, 20, 20)'}} label="Mécanique" />
          <Chip onClick={() => this.selectChip(2)} style={{color: this.state.selected[2] ? 'black' : 'white', backgroundColor: this.state.selected[2] ? 'white' : 'rgb(20, 20, 20)'}} label="Biologie" />
          <Chip onClick={() => this.selectChip(3)} style={{color: this.state.selected[3] ? 'black' : 'white', backgroundColor: this.state.selected[3] ? 'white' : 'rgb(20, 20, 20)'}} label="Informatique" />
          <Chip onClick={() => this.selectChip(4)} style={{color: this.state.selected[4] ? 'black' : 'white', backgroundColor: this.state.selected[4] ? 'white' : 'rgb(20, 20, 20)'}} label="E-Commerce" />

        </Grid>

        <Grid style={styles.listContainer} sm={12} md={12} xs={12}>
          
           <List>

              {
                this.state.persons.length === 0 && this.state.projects.length === 0 ?
                <ListItemText style={{color: 'white'}} primary="Aucun élément à afficher" />
                :
                null
              }

              {
                this.state.projects.map((el) => {
                  return (
                    <ListItem key={el.id} sx={{ border: 1 }} style={styles.itemContainer} disablePadding>
                      <ListItemButton onClick={() => window.location="/project/"+el.id} >
                        <ListItemIcon>
                          <Avatar src={this.state.photos[el.id]} style={{color: 'white'}} />
                        </ListItemIcon>
                        <ListItemText disableTypography primary={<Typography variant="h5" style={{ color: '#FFFFFF' }}>{el.name}</Typography>} secondary={<Typography variant="description" style={{ color: '#FFFFFF' }}>{el.description}</Typography>} />
                      </ListItemButton>
                    </ListItem>
                    )
                })
              }

              {
                this.state.persons.map((el) => {
                  return (
                    <ListItem key={el.id} secondaryAction={<IconButton onClick={() => this.toggleSub(el.id)} edge="end"> <PodcastsIcon style={{color: el.tick ? COLORS.secondary : 'grey'}} /> </IconButton>} sx={{ border: 1 }} style={styles.itemContainer} disablePadding>
                      <ListItemButton onClick={() => window.location="/user/"+el.id} >
                        <ListItemIcon>
                          <Avatar src={this.state.photos[el.id]} style={{color: 'white'}} />
                        </ListItemIcon>
                        <ListItemText style={{color: 'white'}} primary={el ? (el.name[0].toUpperCase() + " " + el.name[1][0].toUpperCase() + el.name[1].substring(1)) : ""} />
                      </ListItemButton>
                    </ListItem>
                    )
                })
              }

            </List>

        </Grid>

      </Grid>
    )
  }
}

const styles = {
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
  },

  listContainer: {
    padding: 20,
  },

  itemContainer: {
    borderColor: COLORS.secondary,
    borderRadius: 5,
    marginBottom: 20,
    paddnig: 10
  }
}



export default function Search_({user}) {
  let params = useParams();
  return <Search keywords={params.keywords} user={user} />
}







