import { Component } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import COLORS from '../assets/colors';
import { checkFormat } from '../utils/checkers';
import fire from '../fire';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";
const auth = getAuth(fire);

export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",

      isLoging: false
    };
  }

  componentDidMount() {
    this.props.hideButtonsInAppHeader();

    onAuthStateChanged(auth, (user) => {
      if (user) {
        //window.location="./";
      } else {
        //
      }
    });

  }

  componentWillUnmount() {
  }

  login() {
    if(!checkFormat(this.state.email, "email") || this.state.password === "") {
      alert("Mauvais identifiants !")
    } else {
        this.setState({isLoading: true, wrongPw: false}, () => {
                  signInWithEmailAndPassword(auth, this.state.email, this.state.password)
                  .then(() => {
                    this.setState({isLoading: false}, () => {
                      //
                    })
                    window.location="/"
                    console.log('User signed in!');
                  })
                  .catch(error => {
                    this.setState({isLoading: false, wrongPw: true});
                    //swal("Oups !", "Impossible de se connecter. Vérifiez les informations saisies.", "error");
                  });
        })
    }
  }

  resetPassword() {
    if(!checkFormat(this.state.email, "email")) {
      alert("Tapez une adresse email valide !")
    } else {
        this.setState({isLoading: true}, () => {
                  sendPasswordResetEmail(auth, this.state.email)
                  .then(() => {
                    this.setState({isLoading: false}, () => {
                      //
                    })
                    alert("Email de réinitialisation envoyé !")
                  })
                  .catch(error => {
                    this.setState({isLoading: false});
                    //swal("Oups !", "Impossible de se connecter. Vérifiez les informations saisies.", "error");
                  });
        })
    }
  }

  render() {
    return (
      <Grid container>

        <Grid container sm={12} md={12} xs={12}>
            <Grid container sm={0} md={4} xs={0}>
            </Grid>
            <Grid item sm={12} md={4} xs={12}>
              <Typography style={styles.header} variant="h2" component="h2"> Connexion </Typography>
              <Typography style={styles.subHeader} variant="body1" component="body1"> Nous sommes ravis de vous revoir !</Typography>
              
              <InputLabel style={styles.InputLabel}>Adresse mail</InputLabel>
              <TextField value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} sx={{ input: { color: 'white' } }} style={styles.inputTextField} helperText={(!checkFormat(this.state.email, "email") && this.state.email !== '') ? 'Adresse invalide !' : null} error={(!checkFormat(this.state.email, "email") && this.state.email !== '')}/>

              <InputLabel style={styles.InputLabel}>Mot de passe</InputLabel>
              <TextField type={'password'} value={this.state.password} onChange={(event) => this.setState({password: event.target.value})} sx={{ input: { color: 'white' } }} style={styles.inputTextField} helperText={this.state.wrongPw ? "Mot de passe incorrect !" : ""} error={this.state.wrongPw} />

              {this.state.isLoading ? <Box style={{marginTop: 20}} sx={{ width: '100%' }}> <LinearProgress /> </Box> : <Button onClick={() => this.login()} style={styles.signInButton} variant="outlined">Se connecter</Button>}

              <p></p>
              <a style={styles.href} href="/signup" > Pas encore inscrit ? Cela ne prend que deux minutes ! </a>
              <p></p>
              <a style={styles.href} href={"#/"} onClick={() => this.resetPassword()} > Mot de passe oublié ? </a>

            </Grid>
            <Grid container sm={0} md={4} xs={0}>
            </Grid>
        </Grid>
        
      </Grid>
      )
  }
}

const styles = {
  header: {
    color: 'white',
    fontWeight: 'bold'
  },
  subHeader: {
    color: 'white',
    marginBottom: 30
  },
  InputLabel: {
    marginTop: 20,
    marginBottom: 5,
    fontWeight: 'bold',
    color: 'white'
  },
  inputTextField: {
    width: '100%',
    backgroundColor: COLORS.appBarGrey,
    color: 'white'
  },
  signInButton: {
    color: COLORS.secondary,
    marginTop: 20,
    width: '100%',
  },
  href: {
    textDecoration: 'none',
    color: COLORS.secondary
  }
};







