import { Component } from 'react';
import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { fr } from "date-fns/locale";
import COLORS from '../../assets/colors';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import Resizer from "react-image-file-resizer";

import { getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
const db = getFirestore();
const storage = getStorage();

export default class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      birthDay: new Date(this.props.user.bday ? (this.props.user.bday+1)*1000*86400 : 0),
      bio: this.props.user.bio ? this.props.user.bio : "",
      name: "",
      forename: "",
    };
  }

  componentDidMount() {

    this.setState({
      name: this.props.user.name[0].toUpperCase(),
      forename: this.props.user.name[1][0].toUpperCase() + this.props.user.name[1].substring(1),
    })


  }

  componentWillUnmount() {
  }

  async onPhotoChanged(e) {
    try {
      const file_ = e.target.files[0];
      await Resizer.imageFileResizer(file_, 192, 192, "png", 100, 0, (file) => {

          const storageRef = ref(storage, 'users/'+this.props.user.id);
          uploadBytes(storageRef, file).then((snap) => {
            window.location="/profile";
          })

      }, "file");
    } catch (err) {
      console.log(err);
    }
  }

  deletePhoto() {
    const storageRef = ref(storage, 'users/'+this.props.user.id);
    deleteObject(storageRef).then(() => {
      window.location="/profile";
    }).catch((error) => {
    });
  }

  async save() {

      const docRef = doc(db, "users", this.props.user.id);

      try {
        await updateDoc(docRef, {
          name: [this.state.name.toLowerCase(), this.state.forename.toLowerCase()],
          bio: this.state.bio,
          bday: parseInt((this.state.birthDay.getTime()/1000)/86400) - 1
        });
        window.location="/profile";
      } catch (e) {
        window.location="/profile";
      }

  }


  render() {
    return (
      <Grid container>

        <input id="photosInput" accept="image/*" type="file" ref={(ref) => this.upload = ref} style={{display: 'none'}} onChange={this.onPhotoChanged.bind(this)} />

        <Grid sm={3} md={3} xs={3}>
  
          <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

            <Avatar src={this.props.user.photo} style={{width: 96, height: 96}} />
            
            <IconButton onClick={() => this.props.user.photo ? this.deletePhoto() : this.upload.click()} color="primary" aria-label="upload picture" component="span">
              {this.props.user.photo ? <DeleteIcon /> : <PhotoCamera />}
            </IconButton>

          </div>

        </Grid>

        <Grid sm={9} md={9} xs={9}>
          
          <Grid divider style={styles.lineContainer}  sm={12} md={12} xs={12}>

            <TextField variant="outlined" label="Nom" value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} sx={{ label: {color: 'white'}, input: { color: 'white' } }} style={styles.inputTextField} helperText={!this.state.name ? "Nom incorrect !" : ""} error={!this.state.name} />

          </Grid>

          <Grid divider style={styles.lineContainer}  sm={12} md={12} xs={12}>

            <TextField variant="outlined" label="Prénom" value={this.state.forename} onChange={(event) => this.setState({forename: event.target.value})} sx={{ label: {color: 'white'}, input: { color: 'white' } }} style={styles.inputTextField} helperText={!this.state.forename ? "Prénom incorrect !" : ""} error={!this.state.forename} />

          </Grid>

        </Grid>

        <Grid divider style={styles.lineContainer}  sm={12} md={12} xs={12}>

          <LocalizationProvider locale={fr} dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Date de naissance"
              inputFormat="MM/dd/yyyy"
              okLabel="OK"
              cancelLabel="Retour"
              value={this.state.birthDay}
              onChange={(newValue) => this.setState({birthDay: newValue})}
              renderInput={(params) => <TextField style={styles.inputTextField} sx={{ label: {color: 'white'}, svg: {backgroundColor: 'white'}, input: {color: 'white'} }} {...params} />}
            />
          </LocalizationProvider>

        </Grid>

        <Grid style={styles.lineContainer}  sm={12} md={12} xs={12}>

          <TextField FormHelperTextProps={{ style: {color: 'white'} }} helperText={this.state.bio.length + "/60"} inputProps={{ maxLength: 60 }} sx={{ label: {color: 'white'}, input: { color: 'white' } }} variant="outlined" label="Bio" value={this.state.bio} onChange={(event) => this.setState({bio: event.target.value})} style={styles.inputTextField} />

        </Grid>

        <Grid style={styles.lineContainer}  sm={12} md={12} xs={12}>

          <Button onClick={() => this.save()} variant="contained">Enregistrer</Button>

        </Grid>
        
      </Grid>
    )
  }
}

const styles = {
  lineContainer: {
    padding: 20,
  },
  inputTextField: {
    width: '100%',
    backgroundColor: COLORS.appBarGrey,
    color: 'white'
  },
};







