import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import fire from '../../fire';


import COLORS from '../../assets/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import SettingsIcon from '@mui/icons-material/Settings';

import Account from './Account';
import Network from './Network';
import Params from './Params';


import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth(fire);


const styles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar
}));

class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 0,
    };
  }

  componentDidMount() {

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.location="/";
      } else {
        //
      }
    });

  }

  componentWillUnmount() {
  }


  render() {
    return (
      <div style={{height: '100%', width: '100%', position: 'absolute', left: 0, top: 0}}>

      <div className={this.props.classes.appBarSpacer} />

      <Grid container style={{height: '100%'}}>

        <Grid sx={{ bgcolor: COLORS.appBarGrey }} sm={3} md={3} xs={3} style={{height: '100%'}}>

              <List>

                <ListItem disablePadding onClick={() => this.setState({selectedIndex: 0})} style={{overflow: 'hidden', paddingLeft: 0, backgroundColor: this.state.selectedIndex === 0 ? 'rgb(20, 20, 20)' : COLORS.appBarGrey}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <AccountBoxIcon style={{color: 'white'}} />
                    </ListItemIcon>
                    <ListItemText primary="Mon compte" style={{color: 'white'}} />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding onClick={() => this.setState({selectedIndex: 1})} style={{overflow: 'hidden', paddingLeft: 0, backgroundColor: this.state.selectedIndex === 1 ? 'rgb(20, 20, 20)' : COLORS.appBarGrey}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <ConnectWithoutContactIcon style={{color: 'white'}} />
                    </ListItemIcon>
                    <ListItemText primary="Réseau" style={{color: 'white'}} />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding onClick={() => this.setState({selectedIndex: 2})} style={{overflow: 'hidden', paddingLeft: 0, backgroundColor: this.state.selectedIndex === 2 ? 'rgb(20, 20, 20)' : COLORS.appBarGrey}}>
                  <ListItemButton>
                    <ListItemIcon>
                      <SettingsIcon style={{color: 'white'}} />
                    </ListItemIcon>
                    <ListItemText primary="Paramètres" style={{color: 'white'}} />
                  </ListItemButton>
                </ListItem>

              </List>


        </Grid>

        <Grid sm={9} md={9} xs={9}>

          {
            this.state.selectedIndex === 0 ?
            <Account user={this.props.user} />
            :
            this.state.selectedIndex === 1 ?
            <Network user={this.props.user} />
            :
            <Params user={this.props.user} />
          }
          
          
        </Grid>

      </Grid>

      </div>

    )
  }
}



export default function Profile_({user}) {
  const classes = styles();
  return <Profile user={user} classes={classes} />
}







