import { Component } from 'react';
import fire from '../../fire';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import LockOpenIcon from '@mui/icons-material/LockOpen';


import { getFirestore, updateDoc, doc } from "firebase/firestore";

import { getAuth, onAuthStateChanged, sendPasswordResetEmail } from "firebase/auth";

const auth = getAuth(fire);
const db = getFirestore();


export default class Params extends Component {

  constructor(props) {
    super(props);
    this.state = {
      notify: this.props.user ? (this.props.user.notify ? true : false) : false
    };
  }

  componentDidMount() {

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.location="/";
      } else {
        this.setState({email: user.email});
      }
    });

  }


  async save() {

      const docRef = doc(db, "users", this.props.user.id);

      try {
        await updateDoc(docRef, {
          notify: this.state.notify,
        });
      } catch (e) {
      }

  }

  sendResetEmail() {
    sendPasswordResetEmail(auth, this.state.email).then(() => {
      this.setState({emailSent: true})
    })
    .catch((e) => {
      console.log("ok : ", e)
    })
   
  }


  render() {
    return (
    <List
      sx={{ width: '100%' }}
    >
      <ListItem>
        <ListItemIcon>
          <CircleNotificationsIcon style={{color: 'white'}}/>
        </ListItemIcon>
        <ListItemText style={{color: 'white'}} primary="Recevoir les notifications" />
        <Switch
          edge="end"
          onChange={() => this.setState({notify: !this.state.notify}, () => this.save())}
          checked={this.state.notify}
        />
      </ListItem>

      <ListItem>
        <ListItemIcon>
          <LockOpenIcon style={{color: 'white'}}/>
        </ListItemIcon>
        <ListItemText style={{color: 'white'}} primary="Changer de mot de passe" />
        <Button
          variant="outlined"
          onClick={() => this.state.emailSent ? null : this.sendResetEmail()}
        >
          { this.state.emailSent ? "Email envoyé" : "Recevoir un mail" }
        </Button>
      </ListItem>

    </List>
      )
  }
}


