export function CheckSchedules (schedules) {
  let schedules_ = JSON.parse(schedules[new Date().getDay()]);
    let d = new Date();
    let now = d.getHours()*60 + d.getMinutes();
    let open = false;
    for(var schedule of schedules_) {
      if(schedule[0] < now && schedule[1] > now) {
        open = true;
      }
    }
    return open
}

export function fromTimeStampToNormalFormat(timestamp) {
  let days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
  let months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"]
  
  let d = new Date(timestamp);
  let today = new Date();

  if(today.getDay() === d.getDay() && today.getDate() === d.getDate() && today.getFullYear() === d.getFullYear()) {
    return "Ajourd'hui à " + completeNumbersWithOneDigit(d.getHours()) + " : " + completeNumbersWithOneDigit(d.getMinutes());
  }

  return "" + days[d.getDay()] + " " + d.getDate() + " " + months[d.getMonth()] + (d.getFullYear() !== today.getFullYear() ? " " + d.getFullYear() : "") + " à " + completeNumbersWithOneDigit(d.getHours()) + " : " + completeNumbersWithOneDigit(d.getMinutes());
}

const completeNumbersWithOneDigit = function(number) {
  if(number <= 9) {
    return '0' + number.toString();
  }
  return number
}