import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import COLORS from '../assets/colors';
import { makeStyles } from '@mui/styles';

import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';


import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import { useParams } from "react-router-dom";

import ReactPlayer from 'react-player/lazy';

import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';

import { fromTimeStampToNormalFormat } from '../utils/formatters';

import { getFirestore, doc, collection, getDoc, getDocs, query, orderBy } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const db = getFirestore();
const storage = getStorage();

class Project extends Component {

  constructor(props) {
    super(props);
    this.state = {
      video: null,
      fundraiser: [],

      name: "",
      description: "",
      website: "",
      twitter: "",
      linkedin: "",
      facebook: "",

      tab: 0
    };
  }

  async componentDidMount() {

        const docRef = doc(db, "projects", this.props.projectId);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
          this.setState(docSnap.data());
        } else {
          window.location = "/";
        }

        getDownloadURL(ref(storage, 'projects/logos/'+this.props.projectId))
        .then((url) => {
          this.setState({logo: url});
        })
        .catch((error) => {
          //console.log("error in getting url : ", error)
        });

        getDownloadURL(ref(storage, 'projects/videos/'+this.props.projectId))
        .then((url) => {
          this.setState({video: url});
        })
        .catch((error) => {
          //console.log("error in getting url : ", error)
        });

        let r = [];
        const querySnapshot = await getDocs(query(collection(db, 'projects/'+this.props.projectId+'/fundraiser')), orderBy('date', 'DESC'));
        querySnapshot.forEach((doc) => {
          r.push(Object.assign(doc.data(), {id: doc.id}));
        });
        this.setState({fundraiser: r})

  }


  render() {

    const classes = this.props.classes;
    const data2 = (amt) => {return [
      {
        name: 'Mois 1',
        amt: parseInt(amt/7),
      },
      {
        name: 'Mois 2',
        amt: parseInt(amt/6),
      },
      {
        name: 'Mois 3',
        amt: parseInt(amt/5),
      },
      {
        name: 'Mois 4',
        amt: parseInt(amt/4),
      },
      {
        name: 'Mois 5',
        amt: parseInt(amt/3),
      },
      {
        name: 'Mois 6',
        amt: parseInt(amt/2),
      },
      {
        name: 'Mois 7',
        amt: parseInt(amt),
      },
    ]};

    return (
      <Grid container>

     <Grid divider sm={12} md={12} xs={12}>

            <div style={styles.bgContainer}>

              { this.state.video ? <ReactPlayer controls={true} url={this.state.video} width='100%' height='100%' /> : null}

            </div>

          </Grid>

          <Grid divider style={styles.leftPannelContainer}  sm={4} md={4} xs={12}>

            <Box style={styles.leftPannelBox}>
              
              <div style={styles.logoContainer}>
                
                { this.state.isUploadingLogo ? <Box> <CircularProgress style={{color: 'tomato'}}/> </Box> : <Avatar src={this.state.logo} sx={{width:98,height:98}}>LOGO</Avatar> }

              </div>

              <div style={styles.logoContainer}>

                <IconButton onClick={() => this.pickLogo()} style={{color: 'white'}} aria-label="upload logo" component="span"> <FileUploadIcon /> </IconButton>
              
              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  label="Nom"
                  fullWidth
                  variant="outlined"
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  value={this.state.name}
                  onChange={(event) => this.setState({name: event.target.value})}
                  className={classes.root}
                  inputProps={ { readOnly: true, } }
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ readOnly: true, style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Description courte"
                  value={this.state.description || ""}
                  onChange={(event) => this.setState({description: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ readOnly: true, style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Site web"
                  value={this.state.website}
                  onChange={(event) => this.setState({website: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LanguageIcon style={{color: 'white'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ readOnly: true, style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Twitter"
                  value={this.state.twitter || ""}
                  onChange={(event) => this.setState({twitter: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterIcon style={{color: '#1DA1F2'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ readOnly: true, style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="LinkedIn"
                  value={this.state.linkedin || ""}
                  onChange={(event) => this.setState({linkedin: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon style={{color: '#0e76a8'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ readOnly: true, style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Facebook"
                  value={this.state.facebook || ""}
                  onChange={(event) => this.setState({facebook: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon style={{color: '#3b5998'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>


            </Box>

          </Grid>

          <Grid divider style={styles.leftPannelContainer} sm={8} md={8} xs={12}>

            <Grid divider style={styles.pannel} sm={12} md={12} xs={12}>
              
                <Box sx={{ width: '100%', bgcolor: 'rgb(20, 20, 20)' }}>
                  <Tabs indicatorColor="secondary" textColor="secondary" variant="fullWidth" value={this.state.tab} onChange={(event, newValue) => this.setState({tab: newValue})} centered>
                    <Tab style={{color: this.state.tab === 0 ? COLORS.main : 'white'}} value={0} label="Statitiques" />
                    <Tab style={{color: this.state.tab === 1 ? COLORS.main : 'white'}} value={1} label="Forum" />
                    <Tab style={{color: this.state.tab === 2 ? COLORS.main : 'white'}} value={2} label="A propos" />
                  </Tabs>
                </Box>

            </Grid>

            {
              this.state.fundraiser.map((fund) => {return (

                    <Grid divider style={styles.pannelWithGraph} sm={12} md={12} xs={12}>


                    {
                      this.state.tab === 0 ?
                      <>
                      <Typography style={{color: 'white', textAlign: 'center'}}>Date de lancement de la levée : {fromTimeStampToNormalFormat(fund["date"])}</Typography>
                      <Typography style={{color: 'white', textAlign: 'center', fontWeight: 'bold'}}>{fund["parts"] + "%"}</Typography>
                      <ResponsiveContainer width="100%" height="80%">
                        <AreaChart
                          width={"100%"}
                          height={"100%"}
                          data={data2(fund["amount"])}
                          margin={{
                            top: 10,
                            right: 0,
                            left: 30,
                            bottom: 0,
                          }}
                        >
                          <XAxis dataKey="name" />
                          <YAxis tickFormatter={(v) => v+"€"} />
                          <Tooltip />
                          <Area type="monotone" dataKey="amt" stroke={"black"} fill={COLORS.secondary} />
                        </AreaChart>
                      </ResponsiveContainer>
                      </>
                      :
                      null
                    }


                    </Grid>

                )})
            }


          </Grid>

      </Grid>
    )
  }
}

const styles = {
  bgContainer: {
    height: 400,
    backgroundColor: 'rgb(20, 20, 20)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  whiteText: {
    color: 'white'
  },
  leftPannelContainer: {
    padding: 10,
  },
  leftPannelBox: {
    padding: 10,
    backgroundColor: 'rgb(20, 20, 20)'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.appBarGrey,
  },
  inputContainer: {
    padding: 10,
  },
  pannel: {
    backgroundColor: 'rgb(20, 20, 20)',
    padding: 10,
    margin: 10,
  },
  pannelWithGraph: {
    backgroundColor: 'rgb(20, 20, 20)',
    padding: 10,
    margin: 10,
    height: 400,
  }
};


const useStyles = makeStyles({
  icon: {
      fill: 'white',
  },
  root: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid white"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white"
    }
  },
  root2: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid white"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white"
    }
  }
})

export default function Project_({user}) {
  const classes = useStyles();
  let params = useParams();
  return <Project projectId={params.projectId} user={user} classes={classes} />
}







