import { Component } from 'react';

import LandingPage from './LandingPage';
import Feed from './app/Feed';


export default class Home extends Component {

  render() {
    if(this.props.isLoged && this.props.user) {
      return (<Feed user={this.props.user} />)
    }
    return <LandingPage />
  }
}



