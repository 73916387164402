import React, { Component } from 'react';

import Navigator from './Navigator';


import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


import Blob from './utils/Blob';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaidIcon from '@mui/icons-material/Paid';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import logo from './assets/images/logo_secondary.png';
import COLORS from './assets/colors';


import fire from './fire';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { getFirestore, doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import FolderIcon from '@mui/icons-material/Folder';

import Landing from './Landing';

const auth = getAuth(fire);
const db = getFirestore();
const storage = getStorage();
const theme = createTheme({
    palette: {
        primary: {
          main: COLORS.secondary
        },
        secondary: {
          main: COLORS.main
        }
    },
});

let inputs = [];
const pass = [84, 69, 84, 65, 82, 68, 67, 65, 80, 73, 84, 65, 76];

export default class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoged: false,
      isOk: false,
      password: "",
      showButtons: true,

      anchorEl: null,

      user: null,

      projects: [],

      showProduction: true,

      searchBarText: ""
    };

    this.kbListener = this.kbListener.bind(this);

    this.search = this.search.bind(this);
  }

   search(e){
      if(e.keyCode === 13){
        localStorage.setItem('searchBar',this.state.searchBarText)
        window.location = "/s/"+this.state.searchBarText;
      }
   }

  kbListener(event){
    inputs.push(event.keyCode);
    if(event.keyCode === 32) {
      inputs = [];
    }
    if(JSON.stringify(inputs) === JSON.stringify(pass)) {
      this.setState({showProduction: false})
      localStorage.setItem('isOK', 'true');
      localStorage.setItem('isOKTime', Date.now().toString());
    }
  }

  componentDidMount() {

    if(localStorage.getItem('isOKTime') && (parseInt(localStorage.getItem('isOKTime')) + 60*60*1000 < Date.now())) {
      localStorage.removeItem('isOK');
    }

    onAuthStateChanged(auth, async (user) => {
      if (user) {

        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if(docSnap.exists()) {

          getDownloadURL(ref(storage, 'users/' + user.uid))
          .then((url) => {
            this.setState({user: Object.assign({}, docSnap.data(), {photo: url, id: user.uid}), isLoged: true, isLoading: false});
          })
          .catch((error) => {
            this.setState({user: Object.assign({}, docSnap.data(), {photo: "", id: user.uid}), isLoged: true, isLoading: false});
          });


          const projectsRef = collection(db, "projects");
          const q = query(projectsRef, where("ownerId", "==", user.uid));
          let r = [];
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            r.push(Object.assign(doc.data(), {id: doc.id}));
          });
          this.setState({projects: r})

        } else {
          signOut(auth);
        }
        
      } else {
        this.setState({isLoged: false, isLoading: false});
        //window.location = "/";
      }
    });

    document.addEventListener("keydown", this.kbListener, false);

    if(window.location.href.includes("/s/")) {
      this.setState({searchBarText: localStorage.getItem('searchBar')})
    }

  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.kbListener, false);
  }

  logout() {
    signOut(auth);
  }

  render() {
    if(this.state.showProduction && !localStorage.getItem('isOK')) {
      return <Landing />
    }
    if(this.state.isLoading) {
      return(
        <div style={styles.blobContainer}>
          <Blob />
        </div>
      )
    }
    return (<ThemeProvider theme={theme}>
              <AppBar style={styles.appBar} position="sticky">
                <Toolbar style={styles.appBarToolbar}>

                  <a href="/">
                    <img style={styles.appBarLogo} src={logo} alt="Logo" />
                  </a>

                  <Grid item container direction="row" alignItems="center" style={styles.appBarButtonsContainer}>
                    <Grid container alignItems="center" sm={11} md={11} xs={11} style={styles.appBarButtonsContainer}>
                      { (this.state.showButtons && !this.state.isLoged) ?
                        <>
                        <Button onClick={() => document.location.href = "/login"} style={styles.signInButton} variant="outlined">Se connecter</Button>
                        <Button onClick={() => document.location.href = "/signup"} style={styles.signUpButton} variant="contained">S'inscrire</Button>
                        <Grid item sm={1} md={1} xs={1}>
                          <Typography style={styles.language} variant="button"> | FR</Typography>
                        </Grid>
                        </>
                        :
                        null
                      }
                      {
                        this.state.isLoged ?
                        <Grid container alignItems="center" sm={12} md={12} xs={12} >
                          <Grid sm={7} md={11} xs={7}>
                            <TextField onKeyDown={this.search} size="small" fullWidth sx={{ input: { backgroundColor: '#202020', color: 'white' } }} value={this.state.searchBarText} onChange={(event) => this.setState({searchBarText: event.target.value})} type="search" placeholder="Rechercher" />
                          </Grid>
                          <Grid container sm={5} md={1} xs={5}>

                            <React.Fragment>
                              <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                                <Tooltip title="Mon compte">
                                  <IconButton onClick={(event) => this.setState({anchorEl: event.currentTarget})} size="small" sx={{ ml: 2 }}>
                                    <Avatar src={this.state.user.photo} sx={{ width: 32, height: 32 }}>{this.state.user ? this.state.user.name[1][0].toUpperCase() : "T"}</Avatar>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                              <Menu
                                anchorEl={this.state.anchorEl}
                                open={this.state.anchorEl ? true : false}
                                onClose={(event) => this.setState({anchorEl: null})}
                                onClick={(event) => this.setState({anchorEl: this.state.anchorEl ? null : event.currentTarget})}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      ml: -0.5,
                                      mr: 1,
                                    },
                                    '&:before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      right: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                    },
                                  },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                              >
                                <MenuItem onClick={() => window.location="/profile"}>
                                  <Avatar /> Mon compte
                                </MenuItem>
                                <MenuItem onClick={() => window.location="/wallet"}>
                                  <ListItemIcon>
                                    <AccountBalanceWalletIcon fontSize="small" />
                                  </ListItemIcon>
                                  Portefeuille
                                </MenuItem>
                                <MenuItem onClick={() => window.location="/payments_methods"}>
                                  <ListItemIcon>
                                    <PaidIcon fontSize="small" />
                                  </ListItemIcon>
                                  Moyens de paiements
                                </MenuItem>
                                <Divider />
                                {
                                  this.state.projects.map((pr) => {
                                    return (<MenuItem onClick={() => window.location = "/editProject/"+pr["id"]}>
                                      <ListItemIcon>
                                        <FolderIcon fontSize="small" />
                                      </ListItemIcon>
                                      {pr['name']}
                                    </MenuItem>)
                                  })
                                }
                                { this.state.projects.length > 0 ? <Divider /> : null }
                                <MenuItem onClick={() => window.location="/editProject/new"}>
                                  <ListItemIcon>
                                    <CreateNewFolderIcon fontSize="small" />
                                  </ListItemIcon>
                                  Ajouter un projet
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={() => this.logout()}>
                                  <ListItemIcon>
                                    <Logout fontSize="small" />
                                  </ListItemIcon>
                                  Se déconnecter
                                </MenuItem>
                              </Menu>
                            </React.Fragment>
                            
                          </Grid>                          
                        </Grid>
                        :
                        null
                      }
                    </Grid>
                  </Grid>

                </Toolbar>
              </AppBar>

                
              <Navigator user={this.state.user} isLoged={this.state.isLoged} hideButtonsInAppHeader={() => this.setState({showButtons: false})} />
                

            </ThemeProvider>)
  }
}

const styles = {
  appBar: {
    background: COLORS.appBarGrey
  },
  appBarToolbar: {
    justifyContent: 'space-between',
  },
  logoContainer: {
    display: 'flex',
  },
  appBarLogo: {
    height: 50,
  },
  appBarLogoName: {
    color: COLORS.secondary
  },
  appBarButtonsContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  language: {
    color: 'white',
  },
  signUpButton: {
    color: 'white',
    marginRight: 15,
    backgroundColor: COLORS.primary
  },
  signInButton: {
    color: COLORS.secondary,
    marginRight: 15,
  },
  blobContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
};







