// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDjU718tOJT2ujw2J3-stjUZK3uZO_8PK4",
  authDomain: "tetard-capital.firebaseapp.com",
  projectId: "tetard-capital",
  storageBucket: "tetard-capital.appspot.com",
  messagingSenderId: "136648731887",
  appId: "1:136648731887:web:3335493ce26f304a9dff1a",
  measurementId: "G-JVD14KKZB1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;