import { Component } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PodcastsIcon from '@mui/icons-material/Podcasts';


import COLORS from '../../assets/colors';

import { getFirestore, collection, getDocs, doc, getDoc } from "firebase/firestore"; 
import { getStorage, ref, getDownloadURL } from "firebase/storage";
const storage = getStorage();
const db = getFirestore();


export default class Network extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,

      list1: [],
      list2: [],
      isLaoding: true,

      users1: {},
      users2: {},
      photos: {},
    };
  }

  componentDidMount() {

    this.getLists().then((res) => {
      this.setState({list1: res[0], isLoading: false, list2: res[1]}, async () => {

        res[0].forEach(async (user1) => {
          let docRef = doc(db, "users", user1.id);
          let docSnap = await getDoc(docRef);
          this.setState({users1: Object.assign( this.state.users1, {[user1.id]: docSnap.data()} ) })

          getDownloadURL(ref(storage, 'users/' + user1.id))
          .then((url) => {
            this.setState({photos: Object.assign( this.state.photos, {[user1.id]: url} ) })
          })
          .catch((error) => {
          });
          
        });

        res[1].forEach(async (user2) => {
          let docRef = doc(db, "users", user2.id);
          let docSnap = await getDoc(docRef);
          this.setState({users2: Object.assign( this.state.users1, {[user2.id]: docSnap.data()} ) })

          getDownloadURL(ref(storage, 'users/' + user2.id))
          .then((url) => {
            this.setState({photos: Object.assign( this.state.photos, {[user2.id]: url} ) })
          })
          .catch((error) => {
          });
          
        });


      });
    })

  }

  getLists() {
    return new Promise(async (resolve, reject) => {

      let list1 = [];
      const querySnapshot1 = await getDocs(collection(db, "users/"+this.props.user.id+"/subscribers"));
      await querySnapshot1.forEach(async (doc_) => {
        list1.push({tick: true, id: doc_.id});
      });

      let list2 = [];
      const querySnapshot2 = await getDocs(collection(db, "users/"+this.props.user.id+"/subs"));
      await querySnapshot2.forEach(async (doc_2) => {
        list2.push({tick: true, id: doc_2.id});
      });

      resolve([list1, list2])

    });
  }


  render() {
    return (
      <Grid container>

        <Grid style={styles.buttonContainer} sm={6} md={6} xs={12}>
          
          <Button onClick={() => this.setState({selectedTab: 0})} fullWidth variant={this.state.selectedTab === 0 ? "outlined" : "text"}>ABONNÉS</Button>

        </Grid>
        <Grid style={styles.buttonContainer} sm={6} md={6} xs={12}>

          <Button onClick={() => this.setState({selectedTab: 1})} fullWidth variant={this.state.selectedTab === 1 ? "outlined" : "text"}>ABONNEMENTS</Button>
          
        </Grid>

        <Grid style={styles.listContainer} sm={12} md={12} xs={12}>
          
           <List>


              {
                this.state.isLoading ?
                <Box sx={{ width: '100%' }}> <LinearProgress /> </Box>
                :
                null
              }

              {
                this.state.selectedTab === 0 ?
                this.state.list1.length === 0 ?
                <ListItemText style={{color: 'white'}} primary="Aucun élément à afficher" />
                :
                this.state.list1.map((el) => {
                  return (
                    <ListItem onClick={() => window.location="/user/"+el.id} key={el.id} secondaryAction={<IconButton edge="end"> <PodcastsIcon style={{color: el.tick ? COLORS.secondary : 'grey'}} /> </IconButton>} sx={{ border: 1 }} style={styles.itemContainer} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Avatar src={this.state.photos[el.id]} style={{color: 'white'}} />
                        </ListItemIcon>
                        <ListItemText style={{color: 'white'}} primary={this.state.users1[el.id] ? (this.state.users1[el.id].name[0].toUpperCase() + " " + this.state.users1[el.id].name[1][0].toUpperCase() + this.state.users1[el.id].name[1].substring(1)) : ""} />
                      </ListItemButton>
                    </ListItem>
                    )
                })
                :
                null
              }

              {
                this.state.selectedTab === 1 ?
                this.state.list2.length === 0 ?
                <ListItemText style={{color: 'white'}} primary="Aucun élément à afficher" />
                :
                this.state.list2.map((el) => {
                  return (
                    <ListItem onClick={() => window.location="/user/"+el.id} key={el.id} secondaryAction={<IconButton edge="end"> <PodcastsIcon style={{color: el.tick ? COLORS.secondary : 'grey'}} /> </IconButton>} sx={{ border: 1 }} style={styles.itemContainer} disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <Avatar src={this.state.photos[el.id]} style={{color: 'white'}} />
                        </ListItemIcon>
                        <ListItemText style={{color: 'white'}} primary={this.state.users2[el.id] ? (this.state.users2[el.id].name[0].toUpperCase() + " " + this.state.users2[el.id].name[1][0].toUpperCase() + this.state.users2[el.id].name[1].substring(1)) : ""} />
                      </ListItemButton>
                    </ListItem>
                    )
                })
                :
                null
              }

            </List>

        </Grid>

      </Grid>
      )
  }
}



const styles = {
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10
  },

  listContainer: {
    padding: 20,
  },

  itemContainer: {
    borderColor: COLORS.secondary,
    borderRadius: 5,
    marginBottom: 20,
    height: 60,
  }
}