import { Component } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LogoBlack from './assets/images/logo_transparent.png';



class LandingComponent extends Component {

  render() {
    const fontSize = window.innerWidth > 470 ? 120 : (window.innerWidth > 470 ? 90 : 60);
    return (

      <div style={{position: 'absolute', left: 0, top: 0, height: '100%', width: '100%'}} className="gradientBgMain">
        
      <Grid container>

        <Grid container direction="row" alignItems="center">

          <Grid style={Object.assign({}, styles.centerContent, {})} direction="row" sm={12} md={12} xs={12} container>
            
            <img style={{height: window.innerWidth > 470 ? 240 : 180, marginRight: 15}} src={LogoBlack} alt="Logo" />

            <div>
              <Typography style={Object.assign({}, styles.homeSlogan, {color: 'black', fontSize: fontSize, marginTop: fontSize/2})}  variant="h2"> TETARD </Typography>
              <Typography style={Object.assign({}, styles.homeSlogan, {color: 'black', fontSize: fontSize, marginTop: -fontSize/2})}  variant="h2"> Capital </Typography>
            </div>

          </Grid>

        </Grid>

        <Grid style={Object.assign({}, styles.centerContent, {})} sm={12} md={12} xs={12} item>

          <Typography style={Object.assign({}, styles.homeSlogan, {color: 'black', fontSize: 24})}  variant="h2"> Coming soon </Typography>

        </Grid>

        <div style={Object.assign({}, styles.centerContent, {width: '100%', position: 'absolute', bottom: 0, padding: 10})}>

          <IconButton href="https://www.linkedin.com/company/tetard-capital" style={{marginRight: 10, backgroundColor: 'white'}} aria-label="LinkedIn">
            <LinkedInIcon style={{width: 32, height: 32, color: '#0e76a8'}} />
          </IconButton>

          <IconButton href="mailto:contact@tetardcapital.com" style={{marginLeft: 10, backgroundColor: 'white'}} aria-label="Email">
            <EmailIcon style={{width: 32, height: 32, color: 'black'}} />
          </IconButton>

        </div>

      </Grid>

      </div>
      )
  }
}

const styles = {
  homeSlogan: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Genos',
    fontSize: 48
  },
  homeSlogan2: {
    color: 'white',
    fontWeight: 'bold',
    paddingTop: 20,
    paddingBottom: 20,
  },

  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};


export default function Landing() {

  return <LandingComponent />
}

