import { Component } from 'react';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import android from '../assets/images/android.png';
import ios from '../assets/images/ios.png';
import payment_methods from '../assets/images/payment_methods.png';

import iphone1 from '../assets/images/iphone1.png';
import iphone2 from '../assets/images/iphone2.png';

import logo1 from '../assets/images/fake/logo1.png';
import logo2 from '../assets/images/fake/logo2.png';
import logo3 from '../assets/images/fake/logo3.png';
import logo4 from '../assets/images/fake/logo4.png';
import logo5 from '../assets/images/fake/logo5.png';


import SmallStatsInfo from '../utils/SmallStatsInfo';


import Instagram from '@mui/icons-material/Instagram';
import Twitter from '@mui/icons-material/Twitter';
import Facebook from '@mui/icons-material/Facebook';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import LinkedIn from '@mui/icons-material/LinkedIn';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { LineChart, Line } from 'recharts';

import LogoBlack from '../assets/images/logo_transparent.png';

import { Parallax, ParallaxLayer } from '@react-spring/parallax'


function getCurvedata() {
  let data = [
    {index: 0, data: Math.random()},
    {index: 1, data: Math.random()},
    {index: 2, data: Math.random()},
    {index: 3, data: Math.random()},
    {index: 4, data: Math.random()},
    {index: 5, data: Math.random()},
    {index: 6, data: Math.random()},
    {index: 7, data: Math.random()},
    {index: 8, data: Math.random()},
    {index: 9, data: Math.random()},
  ];
  return data
}


const rows = [
  {logo: logo1, name: "Boxx", price: 178, percent: 78, curve: getCurvedata()},
  {logo: logo2, name: "The Shower", price: 178, percent: 78, curve: getCurvedata()},
  {logo: logo3, name: "The Tasman", price: 178, percent: 78, curve: getCurvedata()},
  {logo: logo4, name: "NINU", price: 178, percent: 78, curve: getCurvedata()},
  {logo: logo5, name: "Eyekeepit", price: 987, percent: 78, curve: getCurvedata()},
];

const percents = [
  1.88,
  11.21,
  7.4,
  -0.28,
  -0.02
];

const prices = [
  10.97,
  1.02,
  20.87,
  43.67,
  89.87
];


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


export default class LandingPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      percents: [0.1, -0.4, 1, 1.2, 0.87],
      prices: [0.1, -0.4, 1, 1.2, 0.9],
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.setState({ percents: [(Math.random() > 0.5 ? 1 : -1)*Math.random(), (Math.random() > 0.5 ? 1 : -1)*Math.random(), (Math.random() > 0.5 ? 1 : -1)*Math.random(), (Math.random() > 0.5 ? 1 : -1)*Math.random()], prices: [(Math.random() > 0.5 ? 1 : -1)*Math.random(), (Math.random() > 0.5 ? 1 : -1)*Math.random(), (Math.random() > 0.5 ? 1 : -1)*Math.random(), (Math.random() > 0.5 ? 1 : -1)*Math.random(), (Math.random() > 0.5 ? 1 : -1)*Math.random()] }), 2000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  round(num) {
    return Math.round(num * 100) / 100
  }

  render_() {
    return (

<Parallax pages={2} style={{ top: '0', left: '0' }}>
  <ParallaxLayer
    offset={0}
    speed={2.5}
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <p>Scroll down</p>
  </ParallaxLayer>

  <ParallaxLayer offset={1} speed={2} style={{ backgroundColor: '#ff6d6d' }} />

  <ParallaxLayer
    offset={1}
    speed={0.5}
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
    }}>
    <p>Scroll up</p>
  </ParallaxLayer>
</Parallax>

      )
  }

  render() {
    return (
            <Grid container style={{backgroundColor: 'rgb(24, 26, 31)'}}>

                <Grid className="gradientBgMain" container direction="row" alignItems="center">

                  <Grid style={Object.assign({}, styles.centerContent, {paddingTop: 50, paddingBottom: 50})} direction="row" sm={12} md={12} xs={12} container>
                    
                    <img style={{height: 240, marginRight: 15}} src={LogoBlack} alt="Logo" />

                    <div>
                      <Typography style={Object.assign({}, styles.homeSlogan, {color: 'black', fontSize: 120, marginTop: 60})}  variant="h2"> TETARD </Typography>
                      <Typography style={Object.assign({}, styles.homeSlogan, {color: 'black', fontSize: 120, marginTop: -60})}  variant="h2"> Capital </Typography>
                    </div>

                  </Grid>

                </Grid>

                <Grid>

                  <Grid sm={12} md={12} xs={12} container style={{paddingTop: 20, paddingBottom: 20, backgroundColor: 'rgb(20, 20, 20)'}}>

                    <Grid style={{paddingLeft: 50, justifyContent: 'center'}} sm={12} md={8} xs={12} item>
                      <Typography style={Object.assign({}, styles.homeSlogan2, {color: 'white'})} variant="h6"> Rejoignez le premier réseau social de crowdinvesting au monde </Typography>
                    </Grid>

                    <Grid sm={12} md={4} xs={12} item>

                    <div style={styles.centerContent}>    
                        <a href="https://tetardcapital.com"><img style={styles.downloadButton} src={ios} alt="android" /></a>
                        <a href="https://tetardcapital.com"><img style={Object.assign({}, styles.downloadButton, {marginLeft: 40})} src={android} alt="ios" /></a>
                    </div>

                    </Grid>

                  </Grid>


                  <Grid className="gradientBgSecondary" container direction="row">

                    <Grid style={{marginTop: 10}} spacing={2} xs={12} sm={6} md={6} direction="row" container>

                      <Grid sm={6} md={6} xs={6} item style={{display: 'flex', justifyContent: 'center'}}>
                        <SmallStatsInfo name={rows[0]["name"]} percent={this.round(percents[0] + this.state.percents[0])} price={this.round(prices[0] + this.state.prices[0])} />
                      </Grid>

                      <Grid sm={6} md={6} xs={6} item style={{display: 'flex', justifyContent: 'center'}}>
                        <SmallStatsInfo name={rows[1]["name"]} percent={this.round(percents[1] + this.state.percents[1])} price={this.round(prices[1] + this.state.prices[1])} />
                      </Grid>
                      
                    </Grid>

                    <Grid style={{marginTop: 10}} spacing={2} xs={12} sm={6} md={6} direction="row" container>

                      <Grid sm={6} md={6} xs={6} item style={{display: 'flex', justifyContent: 'center'}}>
                        <SmallStatsInfo name={rows[2]["name"]} percent={this.round(percents[2] + this.state.percents[2])} price={this.round(prices[2] + this.state.prices[2])} />
                      </Grid>

                      <Grid sm={6} md={6} xs={6} item style={{display: 'flex', justifyContent: 'center'}}>
                        <SmallStatsInfo name={rows[3]["name"]} percent={this.round(percents[3] + this.state.percents[3])} price={this.round(prices[3] + this.state.prices[3])} />
                      </Grid>
                      
                    </Grid>

                  </Grid>


                  <Grid container direction="row">

                    <Grid style={{paddingLeft: 40}} sm={12} md={6} xs={12} item>
                      <Typography style={{color: 'white', paddingTop: 50, fontWeight: 'bold'}} variant="h3"> Ensemble, sur un même réseau  </Typography>
                      <Typography style={{color: 'white', paddingTop: 20, fontWeight: 'bold'}} variant="h6"> Rejoignez vos amis et formez une communauté forte, pour imposer la finance de demain. </Typography>
                    </Grid>

                    <Grid style={{padding: 20, overflow: 'hidden', display: 'flex'}} sm={12} md={6} xs={12} item>
                      <img style={styles.phoneCapture} src={iphone1} alt="Logo" />
                      <img style={styles.phoneCapture} src={iphone2} alt="Logo" />
                    </Grid>

                  </Grid>


                  <Grid container direction="row" alignItems="center">

                    <Typography style={{color: 'white', marginLeft: 50, paddingTop: 50, fontWeight: 'bold'}} variant="h3"> Tendances </Typography>

                  </Grid>


                  <Grid container direction="row" alignItems="center" style={{padding: 50}}>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow style={{backgroundColor: 'black'}}>
                            <StyledTableCell></StyledTableCell>
                            <StyledTableCell style={{fontSize: 11, color: 'grey', fontWeight: 'bold'}}>Nom</StyledTableCell>
                            <StyledTableCell align="right" style={{fontSize: 11, color: 'grey', fontWeight: 'bold'}}>Dernier prix</StyledTableCell>
                            <StyledTableCell align="right" style={{fontSize: 11, color: 'grey', fontWeight: 'bold'}}>Variation 24H</StyledTableCell>
                            <StyledTableCell align="left" style={{fontSize: 11, color: 'grey', fontWeight: 'bold'}}>Marché</StyledTableCell>
                            <StyledTableCell align="right" style={{fontSize: 11, color: 'grey', fontWeight: 'bold'}}></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row, index) => (
                            <StyledTableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <StyledTableCell component="th" scope="row"> { <img alt={row.name} style={{width: 64, height: 64}} src={row.logo} /> }  </StyledTableCell>
                              <StyledTableCell component="th" scope="row" style={{fontSize: 18, fontWeight: 'bold', color: 'black'}}> {row.name} </StyledTableCell>
                              <StyledTableCell align="right" style={{fontSize: 22}} > {"€"+this.round(prices[index] + this.state.prices[index]).toString().replace(".", ",")} </StyledTableCell>
                              <StyledTableCell align="right" style={{fontWeight: 'bold', color: this.round(percents[index]) > 0 ? "#2ecc71" : "tomato"}}> {(this.round(percents[index]) > 0 ? "+" : "") + this.round(percents[index])} </StyledTableCell>
                              <StyledTableCell align="right">
                                <LineChart width={80} height={80} data={row.curve}>
                                  <Line dataKey="data" stroke={"#e15f41"} />
                                </LineChart>
                              </StyledTableCell>
                              <StyledTableCell align="right"><Button style={{textTransform: 'none', background: 'grey', color: 'white'}} variant="contained">Acheter</Button></StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                  </Grid>


                  <Grid container direction="row" alignItems="center">

                    <Grid style={{paddingLeft: 50}} sm={12} md={7} xs={12} item>
                      <Typography style={{color: 'white', paddingTop: 50, fontWeight: 'bold'}} variant="h3"> Des paiements variés et sécurisés </Typography>
                      <Typography style={styles.homeSlogan2} variant="h6"> Payez facilement : carte, virement ou crypto-monnaie ! </Typography>
                    </Grid>

                    <Grid style={{padding: 20, overflow: 'hidden'}} sm={12} md={5} xs={12} item>
                      <img style={styles.appBarLogo} src={payment_methods} alt="Logo" />
                    </Grid>

                  </Grid>



                  <Grid container direction="row" alignItems="center">

                    <Typography style={{color: 'white', marginLeft: 50, paddingTop: 50, fontWeight: 'bold'}} variant="h3"> Rejoingnez-nous </Typography>

                  </Grid>


                  <Grid container direction="row" alignItems="center" style={{padding: 50}}>

                      <Grid sm={3} md={3} xs={3} item style={{display: 'flex', justifyContent: 'center'}}>
                        <LinkedIn style={{color: '#0088CC', width: 58, height: 58}} />
                      </Grid>

                      <Grid sm={3} md={3} xs={3} item style={{display: 'flex', justifyContent: 'center'}}>
                        <Twitter style={{color: '#00aace', width: 58, height: 58}} />
                      </Grid>

                      <Grid sm={3} md={3} xs={3} item style={{display: 'flex', justifyContent: 'center'}}>
                        <Facebook style={{color: '#3b5998', width: 58, height: 58}} />
                      </Grid>

                      <Grid sm={3} md={3} xs={3} item style={{display: 'flex', justifyContent: 'center'}}>
                        <Instagram style={{color: '#e95950', width: 58, height: 58}} />
                      </Grid>

                  </Grid>


                  <Grid item xs={12} md={12} sm={12}>

                    <Grid style={{backgroundColor: 'black', padding: 20}} className="general__container" container spacing={1}>

                      <Grid item xs={12} md={4} sm={4}>

                        <Typography style={{color: 'white'}} variant="h6" component="h6">Téléchargement</Typography>

                        <List component="nav" aria-label="download links">
                          <ListItem button onClick={() => window.open("https://www.apple.com/fr/app-store/")}> <ListItemIcon> <AppleIcon style={{color: "#7d7d7d"}}/> </ListItemIcon> <ListItemText style={{color: 'white'}} primary="iOS" /> </ListItem>
                          <Divider color='white' />
                          <ListItem button onClick={() => window.open("https://play.google.com/store/")}> <ListItemIcon> <AndroidIcon style={{color: "#a4c639"}}/> </ListItemIcon> <ListItemText style={{color: 'white'}} primary="Android" /> </ListItem>
                          <Divider color='white' />
                        </List>

                      </Grid>

                      <Grid item xs={12} md={4} sm={4}>

                        <Typography style={{color: 'white'}} variant="h6" component="h6">Rester connecté</Typography>

                        <List component="nav" aria-label="networks links">
                          <ListItem button onClick={() => window.open("")}> <ListItemIcon> <LinkedIn style={{color: "#0e76a8"}}/> </ListItemIcon> <ListItemText style={{color: 'white'}} primary="LinkedIn" /> </ListItem>
                          <Divider color='white' />
                          <ListItem button onClick={() => window.open("")}> <ListItemIcon> <Twitter style={{color: "#00acee"}}/> </ListItemIcon> <ListItemText style={{color: 'white'}} primary="Twitter" /> </ListItem>
                          <Divider color='white' />
                          <ListItem button onClick={() => window.open("")}> <ListItemIcon> <Facebook style={{color: "#0088CC"}}/> </ListItemIcon> <ListItemText style={{color: 'white'}} primary="Facebook" /> </ListItem>
                          <Divider color='white' />
                          <ListItem button onClick={() => window.open("")}> <ListItemIcon> <Instagram style={{color: "#e95950"}}/> </ListItemIcon> <ListItemText style={{color: 'white'}} primary="Instagram" /> </ListItem>
                          <Divider color='white' />
                        </List>


                      </Grid>

                      <Grid item xs={12} md={4} sm={4}>

                        <Typography style={{color: 'white'}} variant="h6" component="h6">À propos</Typography>

                        <List component="nav" aria-label="conditions of use">
                          <ListItem button onClick={() => window.open("https://tetardcapital.com/privacy")}> <ListItemText style={{color: 'white'}} primary="Conditions générales d'utilisation" /> </ListItem>
                          <Divider color='white' />
                          <ListItem button onClick={() => window.open("https://tetardcapital.com/privacy")}> <ListItemText style={{color: 'white'}} primary="Règles de confidentialité" /> </ListItem>
                          <Divider color='white' />
                        </List>

                      </Grid>

                    </Grid>

                  </Grid>

                </Grid>


            </Grid>
          )
  }
}

const styles = {
  homeSlogan: {
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'Genos',
    fontSize: 48
  },
  homeSlogan2: {
    color: 'white',
    fontWeight: 'bold',
    paddingTop: 20,
    paddingBottom: 20,
  },
  signUpButton: {
    color: 'white',
    marginRight: 15,
  },
  logo: {
    /*maxWidth: 200,
    height: null,*/
    //backgroundSize: 'contain',
  },
  downloadButton: {
    width: 148,
    borderRadius: 10,
  },
  phoneCapture: {
    borderRadius: 30,
    paddingLeft: 10,
  },

  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};







