import { Component } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

export default class SmallStatsInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }

  render() {
    return (
        <Card style={{width: 240, backgroundColor: '#202020'}}>
          <CardContent>

            <Grid container>

              <Grid container direction="row" alignItems="center">

                <Grid sm={9} md={9} xs={9} item>

                  <Typography style={{fontWeight: 'bold', color: 'white'}} > { this.props.name } </Typography>

                </Grid>

                <Grid sm={3} md={3} xs={3} item style={{display: 'flex', justifyContent: 'flex-end'}}>

                  <Typography style={{fontWeight: 'bold', color: this.props.percent > 0 ? "#2ecc71" : "tomato"}} variant="caption"> { (this.props.percent > 0 ? "+" : "") + this.props.percent + "%" } </Typography>

                </Grid>

              </Grid>

              <Grid sm={12} md={12} xs={12} item>

                <Typography style={{fontWeight: 'bold', color: 'white'}} variant="h5"> { this.props.price + "€"} </Typography>

              </Grid>
              
            </Grid>

          </CardContent>
        </Card>
      )
  }
}





