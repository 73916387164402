import { Component } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Home from './app/Home';
import Login from './app/Login';
import Signup from './app/Signup';
import Payments from './app/app/Payments';
import Wallet from './app/app/Wallet';
import EditProject from './app/app/EditProject';
import Profile from './app/app/Profile';
import Search from './app/Search';
import Project from './app/Project';

export default class Navigator extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {

    return (
      <Router>
        <Routes>

          <Route path="/" element={<Home user={this.props.user} isLoged={this.props.isLoged} />} />

          <Route path="login" element={<Login hideButtonsInAppHeader={() => this.props.hideButtonsInAppHeader()} />} />
          <Route path="signup" element={<Signup hideButtonsInAppHeader={() => this.props.hideButtonsInAppHeader()} />} />

          
          <Route path="payments_methods" element={<Payments user={this.props.user} />} />
          <Route path="profile" element={<Profile user={this.props.user} />} />
          <Route path="wallet" element={<Wallet user={this.props.user} />} />
          <Route path="editProject/:projectId" element={<EditProject user={this.props.user} />} />

          <Route path="s/:keywords" element={<Search user={this.props.user} />} />

          <Route path="project/:projectId" element={<Project user={this.props.user} />} />

        </Routes>
      </Router>
    )

  }

}



