import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import "./blob.css";
import blobs from "blobs";
import COLORS from '../assets/colors';

const options = {
  complexity: 0.3,
  contrast: 0.6,
  guides: true,
  size: 400,
  color: "red",
  stroke: 0
};

const Background = ({ svg, fill }) => (
    <svg viewBox="0 0 400 400" className="wheel">
      <defs>
        <radialGradient id="radial-gradient" cx="100%" fx="100%" fr="0%" r="100%">
          <stop offset="20%" stop-color={COLORS.main} />
          <stop offset="95%" stop-color={COLORS.secondary} />
        </radialGradient>
      </defs>
      <g>
        <animated.path class="blob-gradient" d={svg} />
      </g>
    </svg>
);

export default function Blob() {

  const [blob, change] = useState(blobs.editable(options));


  useEffect(() => {
    const interval = setInterval(() => change(blobs.editable(options)), 100);
    return () => {
      clearInterval(interval);
    };
  }, []);


  const props3 = useSpring({
    svg: blob.children[0].children[0].attributes.d,
    fill: blob.children[0].children[0].attributes.fill
  });

  return (
    <Background svg={props3.svg} fill={props3.fill} />
  );
}

