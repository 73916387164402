import { Component } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import COLORS from '../assets/colors';
import { checkFormat } from '../utils/checkers';
import fire from '../fire';
import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
const db = getFirestore();
const auth = getAuth(fire);

export default class Signup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      name: "",
      forename: "",
    };
  }

  componentDidMount() {
    this.props.hideButtonsInAppHeader();

    onAuthStateChanged(auth, async (user) => {

      if (user) {

        const docRef = doc(db, "users", user.uid);

        const docSnap = await getDoc(docRef);

        if(docSnap.exists()) {

          if(!checkFormat(this.state.email, "email") || this.state.password === "" || this.state.name === "" || this.state.forename === "") {
          } else {
            
            window.location="/";

          }

        } else {

          try {
            await setDoc(docRef, {
              name: [this.state.name.toLowerCase(), this.state.forename.toLowerCase()]
            });
            window.location="/";
          } catch (e) {
          }

        }

      } else {

      }
    });

  }

  componentWillUnmount() {
  }

  login() {
    if(!checkFormat(this.state.email, "email") || this.state.password === "" || this.state.name === "" || this.state.forename === "") {
      alert("Veuillez entrer des identifiants valides !")
    } else {
        this.setState({isLoading: true}, () => {
          createUserWithEmailAndPassword(auth, this.state.email, this.state.password)
          .then(() => {

            signInWithEmailAndPassword(auth, this.state.email, this.state.password)
            .then(() => {
              this.setState({isLoading: false}, () => {
                //
              })
            })
            .catch(error => {
              alert("Une erreur s'est produite !")
            });

          })
          .catch((error) => {
            if(error.code === "auth/email-already-in-use") {
              alert('Email déjà utilisée')
            } else if (error.code === "auth/weak-password") {
              alert("Le mot de passe n'est pas assez long.")
            } else {
              alert("Une erreur s'est produite !")
            }
          });
        })
    }
  }

  resetPassword() {
    alert("hello world")
  }

  render() {
    return (
      <Grid container>

        <Grid container sm={12} md={12} xs={12}>
            <Grid container sm={0} md={4} xs={0}>
            </Grid>
            <Grid item sm={12} md={4} xs={12}>
              <Typography style={styles.header} variant="h2" component="h2"> Inscription </Typography>
              <Typography style={styles.subHeader} variant="body1" component="body1"> Hâte de vous compter parmi nous !</Typography>

              <InputLabel style={styles.InputLabel}>Nom</InputLabel>
              <TextField value={this.state.name} onChange={(event) => this.setState({name: event.target.value})} sx={{ input: { color: 'white' } }} style={styles.inputTextField} />

              <InputLabel style={styles.InputLabel}>Prénom</InputLabel>
              <TextField value={this.state.forename} onChange={(event) => this.setState({forename: event.target.value})} sx={{ input: { color: 'white' } }} style={styles.inputTextField} />

              <InputLabel style={styles.InputLabel}>Adresse mail</InputLabel>
              <TextField value={this.state.email} onChange={(event) => this.setState({email: event.target.value})} sx={{ input: { color: 'white' } }} style={styles.inputTextField} helperText={(!checkFormat(this.state.email, "email") && this.state.email !== '') ? 'Adresse invalide !' : null} error={(!checkFormat(this.state.email, "email") && this.state.email !== '')}/>

              <InputLabel style={styles.InputLabel}>Mot de passe</InputLabel>
              <TextField type={'password'} value={this.state.password} onChange={(event) => this.setState({password: event.target.value})} sx={{ input: { color: 'white' } }} style={styles.inputTextField} />

              {this.state.isLoading ? <Box style={{marginTop: 20}} sx={{ width: '100%' }}> <LinearProgress /> </Box> : <Button onClick={() => this.login()} style={styles.signInButton} variant="outlined">S'inscrire</Button>}

              <p></p>
              <a style={styles.href} href="/login" > Déjà inscrit ? Connectez-vous ici. </a>

            </Grid>
            <Grid container sm={0} md={4} xs={0}>
            </Grid>
        </Grid>
        
      </Grid>
      )
  }
}

const styles = {
  header: {
    color: 'white',
    fontWeight: 'bold'
  },
  subHeader: {
    color: 'white',
    marginBottom: 30
  },
  InputLabel: {
    marginTop: 20,
    marginBottom: 5,
    fontWeight: 'bold',
    color: 'white'
  },
  inputTextField: {
    width: '100%',
    backgroundColor: COLORS.appBarGrey,
    color: 'white'
  },
  signInButton: {
    color: COLORS.secondary,
    marginTop: 20,
    width: '100%',
  },
  href: {
    textDecoration: 'none',
    color: COLORS.secondary
  }
};







