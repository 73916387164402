import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import fire from '../../fire';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import Avatar from '@mui/material/Avatar';
import COLORS from '../../assets/colors';
import ReactPlayer from 'react-player/lazy';


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { getFirestore, doc, addDoc, collection, getDoc, getDocs, query, orderBy } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const db = getFirestore();
const storage = getStorage();

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Feed extends Component {

  constructor(props) {
    super(props);
    this.state = {
      posts: [],

      logos: {},
      videos: {},

      subs: [],

      projectId: null,
    };
  }

  async componentDidMount() {

    let subs = [];
    const q = await getDocs(collection(db, 'users/'+this.props.user.id+'/subscribers'));
    q.forEach((doc) => {
      subs.push({id: doc.id});
    });
    this.setState({subs: subs})

    let feed = [];
    const querySnapshot = await getDocs(query(collection(db, 'users/'+this.props.user.id+'/feed'), orderBy('date', 'desc')));
    querySnapshot.forEach((doc) => {
      feed.push(Object.assign(doc.data(), {id: doc.id}));
    });
    
    feed.forEach(async (el) => {


        const post = await getDoc(doc(db, 'projects', el.projectId));

        if(post.exists()) {

          let add = Object.assign({}, post.data(), {projectId: el.projectId}, {header: el.header ? el.header : false})

          this.setState({posts: this.state.posts.concat([add])})

        }

        getDownloadURL(ref(storage, 'projects/logos/'+el.projectId))
        .then((url) => {
          this.setState({logos: Object.assign(this.state.logos, {[el.projectId]: url})});
        })
        .catch((error) => {
          //console.log("error in getting url : ", error)
        });

        getDownloadURL(ref(storage, 'projects/videos/'+el.projectId))
        .then((url) => {
          this.setState({videos: Object.assign(this.state.videos, {[el.projectId]: url}) });
        })
        .catch((error) => {
          //console.log("error in getting url : ", error)
        });

    })

  }

  putMoney() {

    if(this.state.subs) {

      this.state.subs.forEach(async (sub) => {

        await addDoc(collection(db, 'users/'+sub.id+'/feed'), {
          projectId: this.state.projectId,
          date: Date.now(),
          header: this.props.user.name[1][0].toUpperCase() + this.props.user.name[1].substring(1) + " a investi",
        });

      })



    }


    this.setState({notif: true})


  }

  render() {
    return (

        <Grid container>

          <Grid sm={12} md={3} xs={12}>


            
          </Grid>

          <Grid style={{padding: 15}} sm={12} md={6} xs={12}>

            {
              this.state.posts.map((el) => (

                <Card style={{marginTop: 20, backgroundColor: COLORS.appBarGrey}}>

                  <CardContent>


                      {
                        el.header ?
                          <Typography style={{color: 'white'}} variant="subtitle1" component="subtitle1">
                            {el.header}
                          </Typography>
                        :
                        null
                      }

                    <Grid container>

                      <Grid sm={1} md={1} xs={1}>
                        
                        <Avatar src={this.state.logos[el.projectId]} style={{width: 48, height: 48}}>LOGO</Avatar>

                      </Grid>

                      <Grid sm={11} md={11} xs={11}>
                        <Typography style={{color: 'white'}} variant="h5" component="div">
                          {el.name}
                        </Typography>
                        <Typography style={{color: 'white'}} sx={{ mb: 1.5 }} color="text.secondary">
                          {el.description}
                        </Typography>
                      </Grid>

                      <Grid style={{height: 400}} sm={12} md={12} xs={12}>
                        <ReactPlayer controls={true} url={this.state.videos[el.projectId]} width='100%' height='100%' />
                      </Grid>

                    </Grid>

                  </CardContent>
                  <CardActions>

                    <IconButton aria-label="add to favorites">
                      <FavoriteIcon style={{color: 'white'}} />
                    </IconButton>
                    <IconButton aria-label="share">
                      <ShareIcon style={{color: 'white'}} />
                    </IconButton>
                    <IconButton onClick={() => this.setState({projectId: el.projectId, openDialog: true})} aria-label="buy">
                      <MonetizationOnIcon style={{color: 'white'}} />
                    </IconButton>

                  </CardActions>
                </Card>

              ))
            }
            
          </Grid>


          <Grid sm={12} md={3} xs={12}>
            
          </Grid>

         <Dialog
            open={this.state.openDialog}
            onClose={() => this.setState({openDialog: false})}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                  Êtes-vous sûr de vouloir investir dessus ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({openDialog: false})}>Annuler</Button>
              <Button onClick={() => this.setState({openDialog: false}, () => this.putMoney())} autoFocus>
                INVESTIR
              </Button>
            </DialogActions>
          </Dialog>


          <Snackbar open={this.state.notif} autoHideDuration={1000} onClose={() => this.serState({notif: false})}>
            <Alert onClose={() => this.serState({notif: false})} severity="success" sx={{ width: '100%' }}>
              L'investissement s'est déroulé avec succès !
            </Alert>
          </Snackbar>

        </Grid>

      )
  }
}



