import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import COLORS from '../../assets/colors';
import { makeStyles } from '@mui/styles';
import fire from '../../fire';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Avatar from '@mui/material/Avatar';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';


import Resizer from "react-image-file-resizer";

import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PercentIcon from '@mui/icons-material/Percent';
import EuroIcon from '@mui/icons-material/Euro';

import { useParams } from "react-router-dom";

import ReactPlayer from 'react-player/lazy';

import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';

import { fromTimeStampToNormalFormat } from '../../utils/formatters';

import { getFirestore, doc, addDoc, collection, getDoc, updateDoc, getDocs, query, orderBy } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth(fire);
const db = getFirestore();
const storage = getStorage();


class EditProject extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",

      openSaveButtonDrawer: true,
      openNameDialog: false,
      isUploadingLogo: false,

      fundraiser: [],

      description: "",
      website: "",
      twitter: "",
      linkedin: "",
      facebook: "",
    };
  }

  async componentDidMount() {
    if(this.props.projectId === 'new') {
      this.setState({openNameDialog: true})
    } else {
        const docRef = doc(db, "projects", this.props.projectId);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
          this.setState(docSnap.data());
        } else {
          window.location = "/";
        }

        getDownloadURL(ref(storage, 'projects/logos/'+this.props.projectId))
        .then((url) => {
          this.setState({logo: url});
        })
        .catch((error) => {
          //console.log("error in getting url : ", error)
        });

        getDownloadURL(ref(storage, 'projects/videos/'+this.props.projectId))
        .then((url) => {
          this.setState({video: url});
        })
        .catch((error) => {
          //console.log("error in getting url : ", error)
        });

        let r = [];
        const querySnapshot = await getDocs(query(collection(db, 'projects/'+this.props.projectId+'/fundraiser')), orderBy('date', 'DESC'));
        querySnapshot.forEach((doc) => {
          r.push(Object.assign(doc.data(), {id: doc.id}));
        });
        this.setState({fundraiser: r})
    }

    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.location="/";
      } else {
        //
      }
    });
  }

  componentWillUnmount() {
  }

  addFund() {
    this.setState({isAddingFund: true}, async () => {
      await addDoc(collection(db, 'projects/'+this.props.projectId+'/fundraiser'), {
        amount: this.state.amount,
        parts: this.state.parts,
        date: Date.now()
      });

      this.setState({isAddingFund: false, openNameDialog: false}, () => {
        window.location = "/editProject/" + this.props.projectId
      })
    })
  }

  pickVideo() {
    this.videoUpload.click()
  }

  pickLogo() {
    this.logoUpload.click();
  }

  save() {
    this.setState({isSaving: true}, async () => {
      const docRef = doc(db, "projects", this.props.projectId);
      try {
        await updateDoc(docRef, {
          description: this.state.description,
          website: this.state.website,
          twitter: this.state.twitter,
          linkedin: this.state.linkedin,
          facebook: this.state.facebook,
        });
        this.setState({isSaving: false})
      } catch (e) {
        alert("Quelque chose s'est mal passée !");
        this.setState({isSaving: false})
      }
    })
  }

  createTheProject() {
    this.setState({isAdding: true}, async () => {
      let keywords = (this.state.name.toLowerCase() + " " + this.state.name.toLowerCase().replace("-", " ")).split(" ");
      const docRef = await addDoc(collection(db, "projects"), {
        name: this.state.name,
        s: [...new Set(keywords)],
        ownerId: this.props.user.id,
      });


      let subs = [];
      const q = await getDocs(collection(db, 'users/'+this.props.user.id+'/subscribers'));
      q.forEach((doc) => {
        subs.push({id: doc.id});
      });
      subs.push({id: this.props.user.id});

      subs.forEach(async (sub) => {
        console.log("going to add in feed of " + sub.id)
        await addDoc(collection(db, 'users/'+sub.id+'/feed'), {
          projectId: docRef.id,
          date: Date.now(),
        });
      });

      this.setState({isAdding: false, openNameDialog: false}, () => {
        window.location = "/editProject/" + docRef.id
      })


    })
  }

  async onLogoImageSelected(e) {
    try {
      const file_ = e.target.files[0];

      await Resizer.imageFileResizer(file_, 192, 192, "png", 100, 0, (file) => {

        const storageRef = ref(storage, 'projects/logos/'+this.props.projectId);
        this.setState({isUploadingLogo: true})
        uploadBytes(storageRef, file).then((snap) => {
          this.setState({isUploadingLogo: false}, () => {
            window.location.reload();
          })
        });

      }, "file");
    } catch (err) {
      console.log(err);
    }
  }

  async onVideoSelected(e) {
    try {
      const file = e.target.files[0];

      const storageRef = ref(storage, 'projects/videos/'+this.props.projectId);
      this.setState({isUploadingVideo: true})
      uploadBytes(storageRef, file).then((snap) => {
        this.setState({isUploadingVideo: false}, () => {
          window.location.reload();
        })
      });

    } catch (err) {
      console.log(err);
    }
  }

  render() {

    const classes = this.props.classes;
    const data2 = (amt) => {return [
      {
        name: 'Mois 1',
        amt: parseInt(amt/7),
      },
      {
        name: 'Mois 2',
        amt: parseInt(amt/6),
      },
      {
        name: 'Mois 3',
        amt: parseInt(amt/5),
      },
      {
        name: 'Mois 4',
        amt: parseInt(amt/4),
      },
      {
        name: 'Mois 5',
        amt: parseInt(amt/3),
      },
      {
        name: 'Mois 6',
        amt: parseInt(amt/2),
      },
      {
        name: 'Mois 7',
        amt: parseInt(amt),
      },
    ]};

    return (

      <div style={{marginBottom: 100}}>

        <input accept="image/*" type="file" ref={(ref) => this.logoUpload = ref} style={{display: 'none'}} onChange={this.onLogoImageSelected.bind(this)} />
        <input accept="video/*" type="file" ref={(ref) => this.videoUpload = ref} style={{display: 'none'}} onChange={this.onVideoSelected.bind(this)} />


        <div>

          <React.Fragment>
            <Drawer
              anchor={"bottom"}
              open={this.state.openSaveButtonDrawer}
              onClose={() => this.setState({openSaveButtonDrawer: false})}
              variant="permanent"
              ModalProps={{ keepMounted: true, }}
            >
            <Box borderTop={1} style={styles.drawer} sx={{height: 80, width:'auto'}} role="presentation">
              
              { this.state.isSaving ? <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}> <CircularProgress style={{color: '#28a745'}}/> </Box> : <Button onClick={() => this.save()} style={{color: "#28a745", borderColor: '#28a745'}} variant={"outlined"}>Enregistrer</Button> }
              
            </Box>
            </Drawer>
          </React.Fragment>

        </div>

        <Grid container>

          <Grid divider sm={12} md={12} xs={12}>

            <div style={styles.bgContainer}>

              { this.state.video ? <ReactPlayer controls={true} url={this.state.video} width='100%' height='100%' /> : null}

              { !this.state.video ?
                  this.state.isUploadingVideo ? <Box> <CircularProgress style={{color: 'tomato'}}/> </Box> : <Button onClick={() => this.pickVideo()} style={{backgroundColor: 'rgb(50, 50, 50)'}} variant={"contained"}>Ajouter une vidéo de présentation</Button>
                  :
                  <Button onClick={() => this.pickVideo()} style={{position: 'absolute', top: 80, left: 10, borderColor: 'rgb(50, 50, 50)', color: 'white'}} variant={"outlined"}>Changer de vidéo de présentation</Button>
              }

            </div>

          </Grid>

          <Grid divider style={styles.leftPannelContainer}  sm={4} md={4} xs={12}>

            <Box style={styles.leftPannelBox}>
              
              <div style={styles.logoContainer}>
                
                { this.state.isUploadingLogo ? <Box> <CircularProgress style={{color: 'tomato'}}/> </Box> : <Avatar src={this.state.logo} sx={{width:98,height:98}}>LOGO</Avatar> }

              </div>

              <div style={styles.logoContainer}>

                <IconButton onClick={() => this.pickLogo()} style={{color: 'white'}} aria-label="upload logo" component="span"> <FileUploadIcon /> </IconButton>
              
              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  label="Nom"
                  fullWidth
                  variant="outlined"
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  value={this.state.name}
                  onChange={(event) => this.setState({name: event.target.value})}
                  className={classes.root}
                  inputProps={ { readOnly: true, } }
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Description courte"
                  value={this.state.description || ""}
                  onChange={(event) => this.setState({description: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Site web"
                  value={this.state.website}
                  onChange={(event) => this.setState({website: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LanguageIcon style={{color: 'white'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Twitter"
                  value={this.state.twitter || ""}
                  onChange={(event) => this.setState({twitter: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterIcon style={{color: '#1DA1F2'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="LinkedIn"
                  value={this.state.linkedin || ""}
                  onChange={(event) => this.setState({linkedin: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon style={{color: '#0e76a8'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>

              <div style={styles.inputContainer}>
                  
                <TextField
                  multiline
                  inputProps={{ style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Facebook"
                  value={this.state.facebook || ""}
                  onChange={(event) => this.setState({facebook: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon style={{color: '#3b5998'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>


            </Box>

          </Grid>

          <Grid divider style={styles.leftPannelContainer} sm={8} md={8} xs={12}>

            <Grid divider style={styles.pannel} sm={12} md={12} xs={12}>
              
              <Button fullWidth onClick={() => this.setState({fundingModal: true})} variant={"outlined"}>LANCER UNE NOUVELLE LEVÉE</Button>

            </Grid>

            {
              this.state.fundraiser.map((fund) => {return (

                    <Grid divider style={styles.pannelWithGraph} sm={12} md={12} xs={12}>
                      <Typography style={{color: 'white', textAlign: 'center'}}>Date de lancement : {fromTimeStampToNormalFormat(fund["date"])}</Typography>
                      <Typography style={{color: 'white', textAlign: 'center', fontWeight: 'bold'}}>{fund["parts"] + "%"}</Typography>
                      <ResponsiveContainer width="100%" height="80%">
                        <AreaChart
                          width={"100%"}
                          height={"100%"}
                          data={data2(fund["amount"])}
                          margin={{
                            top: 10,
                            left: 30,
                            bottom: 0,
                          }}
                        >
                          <XAxis dataKey="name" />
                          <YAxis tickFormatter={(v) => v+"€"} />
                          <Tooltip />
                          <Area type="monotone" dataKey="amt" stroke={"black"} fill={COLORS.secondary} />
                        </AreaChart>
                      </ResponsiveContainer>

                    </Grid>

                )})
            }


          </Grid>


         <Dialog PaperProps={{ style: { backgroundColor: COLORS.appBarGrey, boxShadow: "none" }, }} open={this.state.fundingModal} onClose={() => this.setState({fundingModal: false})}>
              <DialogTitle style={styles.whiteText}>Délencher une levée</DialogTitle>
              <DialogContent>
                <DialogContentText style={styles.whiteText}>
                  Veuillez choisir un montant objectif ainsi que la part de vos actions que vous souhaitez vendre
                </DialogContentText>

                <TextField
                  style={{marginTop: 30}}
                  type="number"
                  multiline
                  inputProps={{ style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Montant objectif"
                  value={this.state.amount}
                  onChange={(event) => this.setState({amount: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EuroIcon style={{color: 'white'}} />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  style={{marginTop: 30}}
                  type="number"
                  multiline
                  inputProps={{ style: { color: 'white'}}}
                  fullWidth
                  variant="outlined"
                  label="Parts à vendre"
                  value={this.state.parts}
                  onChange={(event) => this.setState({parts: event.target.value})}
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  className={classes.root}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon style={{color: 'white'}} />
                      </InputAdornment>
                    ),
                  }}
                />

              </DialogContent>
              <DialogActions>
                {this.state.isAddingFund ? null : <Button style={{borderColor: 'tomato', color: 'tomato'}} onClick={() => this.setState({fundingModal: false})}>Annuler</Button>}
                {this.state.isAddingFund ? <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> : <Button disabled={!this.state.amount || !this.state.parts} onClick={() => this.addFund()}>Lancer</Button>}
              </DialogActions>
          </Dialog>




         <Dialog PaperProps={{ style: { backgroundColor: COLORS.appBarGrey, boxShadow: "none" }, }} open={this.state.openNameDialog} onClose={() => this.setState({openNameDialog: this.state.name ? false : true})}>
              <DialogTitle style={styles.whiteText}>Nom du projet</DialogTitle>
              <DialogContent>
                <DialogContentText style={styles.whiteText}>
                  Commençons par donner un nom à votre projet (non modifiable par la suite)
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Nom"
                  fullWidth
                  variant="standard"
                  sx={{ input: { color: 'white' } }}
                  InputLabelProps={{ style: { color: 'white' }, }}
                  value={this.state.name}
                  onChange={(event) => this.setState({name: event.target.value})}
                />
              </DialogContent>
              <DialogActions>
                <Button style={{color: 'tomato'}} onClick={() => window.location="/"}>Annuler</Button>
                {this.state.isAdding ? <Box sx={{ width: '100%' }}> <LinearProgress /> </Box> : <Button disabled={!this.state.name} onClick={() => this.createTheProject()}>Commencer</Button>}
              </DialogActions>
          </Dialog>

        </Grid>

      </div>
    )
  }
}

const styles = {
  bgContainer: {
    height: 400,
    backgroundColor: 'rgb(20, 20, 20)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  whiteText: {
    color: 'white'
  },
  leftPannelContainer: {
    padding: 10,
  },
  leftPannelBox: {
    padding: 10,
    backgroundColor: 'rgb(20, 20, 20)'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.appBarGrey,
  },
  inputContainer: {
    padding: 10,
  },
  pannel: {
    backgroundColor: 'rgb(20, 20, 20)',
    padding: 10,
    margin: 10,
  },
  pannelWithGraph: {
    backgroundColor: 'rgb(20, 20, 20)',
    padding: 10,
    margin: 10,
    height: 400,
  }
};



const useStyles = makeStyles({
  icon: {
      fill: 'white',
  },
  root: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid white"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white"
    }
  },
  root2: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "2px solid white"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid white"
    }
  }
})


export default function EditProject_({user}) {
  let params = useParams();
  const classes = useStyles();
  return <EditProject projectId={params.projectId} user={user} classes={classes} />
}







