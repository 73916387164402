import { Component } from 'react';
import Grid from '@mui/material/Grid';
import COLORS from '../../assets/colors';
import { PieChart, Pie, Sector, ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import fire from '../../fire';



import { getAuth, onAuthStateChanged } from "firebase/auth";
const auth = getAuth(fire);

const data = [
  { name: 'StartUp A', value: 400 },
  { name: 'StartUp B', value: 300 },
  { name: 'StartUp C', value: 300 },
  { name: 'StartUp D', value: 200 },
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="white">{`${value} $`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`( ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const max = 300;

let data2 = [
  {
    name: 'Mois 1',
    amt: Math.floor(Math.random() * max),
  },
  {
    name: 'Mois 2',
    amt: Math.floor(Math.random() * max),
  },
  {
    name: 'Mois 3',
    amt: Math.floor(Math.random() * max),
  },
  {
    name: 'Mois 4',
    amt: Math.floor(Math.random() * max),
  },
  {
    name: 'Mois 5',
    amt: Math.floor(Math.random() * max),
  },
  {
    name: 'Mois 6',
    amt: Math.floor(Math.random() * max),
  },
  {
    name: 'Mois 7',
    amt: Math.floor(Math.random() * max),
  },
];

export default class Wallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
  }

  componentDidMount() {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.location="/";
      } else {
        //
      }
    });
  }

  componentWillUnmount() {
  }

  onPieEnter = (_, index) => {
    data2 = [
      {
        name: 'Mois 1',
        amt: Math.floor(Math.random() * max),
      },
      {
        name: 'Mois 2',
        amt: Math.floor(Math.random() * max),
      },
      {
        name: 'Mois 3',
        amt: Math.floor(Math.random() * max),
      },
      {
        name: 'Mois 4',
        amt: Math.floor(Math.random() * max),
      },
      {
        name: 'Mois 5',
        amt: Math.floor(Math.random() * max),
      },
      {
        name: 'Mois 6',
        amt: Math.floor(Math.random() * max),
      },
      {
        name: 'Mois 7',
        amt: Math.floor(Math.random() * max),
      },
    ];

    this.setState({
      activeIndex: index,
    });
  };

  render() {

    return (
      <Grid container>

        <Grid divider container style={styles.lineContainer}  sm={12} md={12} xs={12}>

          <Grid style={{height: 400}} sm={12} md={5} xs={12}>

            <ResponsiveContainer width="100%" height="100%">
              <PieChart width={"100%"} height={"100%"}>
                <Pie
                  activeIndex={this.state.activeIndex}
                  activeShape={renderActiveShape}
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={90}
                  fill={COLORS.secondary}
                  dataKey="value"
                  onMouseEnter={this.onPieEnter}
                />
              </PieChart>
            </ResponsiveContainer>

          </Grid>

          <Grid style={{height: 400}} sm={12} md={7} xs={12}>

            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={"100%"}
                height={"100%"}
                data={data2}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis dataKey="name" />
                <YAxis tickFormatter={(v) => v+"€"} />
                <Tooltip />
                <Area type="monotone" dataKey="amt" stroke={COLORS.main} fill={COLORS.secondary} />
              </AreaChart>
            </ResponsiveContainer>

          </Grid>

        </Grid>

      </Grid>
    )
  }
}

const styles = {
  lineContainer: {
    height: 400,
    paddingTop: 10,
  }
};







